@import '../../assets/styles/variables.scss';

.navigation {
  background-color: #59b8c5;
  height: 58px;
  position: relative;

    @media screen and (max-width: $screen-mobile) {
        display: none;
    }
}

.navigation-wrapper {
  padding: 0;
  width: 1170px;
  margin: 0 auto;
  max-width: 100%;
}

.navigation-list {
  text-align: left;
  margin: 0;
  padding: 0;
  //position: relative;

  .navigation-item {
    display: inline-block;
    list-style: none;
    margin: 0;
    padding: 0;

    .navigation-item-link {
      display: block;
      padding: 0 30px;
      height: 100%;
      color: #fff;
      text-decoration: none;
      line-height: 58px;
      font-size: 16px;
      font-weight: 500;
      text-transform: uppercase;
      transition: all 0.3s ease;

      &:hover {
        background-color: #4a9fb3;
      }
    }
  }
}
