.price-table-block {
  .price-table-block-header {
    margin-bottom: 30px;

    h3 {
      font-weight: bold;
      font-size: 32px;
      line-height: 36px;
      color: #101828;
      margin-bottom: 8px;
    }
    .price-table-block-under-header {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: rgba(16, 24, 40, 0.7);
    }
  }
}

.price-table {
  display: flex;
  flex-direction: column;
}

.price-table-header {
  display: flex;
  background: #222D31;
  border-radius: 8px;


  .price-table-header-item {
    flex: 1;
    padding: 10px;
    font-weight: 300;
    font-size: 10px;
    line-height: 21px;
    color: #fff;
    vertical-align: middle;
    text-transform: uppercase;
    letter-spacing: 0.1em;

    &:nth-child(1) {
      flex: 2;
    }
  }
}

.price-table-row {
  display: flex;
  border-bottom: 1px solid #E8EFF0;

  .price-table-row-item {
    flex: 1;
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &.bold {
      font-weight: 600;
    }

    &.gray-text {
      color: rgba(16, 24, 40, 0.4);
    }

    &:nth-child(1) {
      flex: 2;
    }
  }
}

.price-item {
  display: block;
  border: 1px solid #E8EFF0;
  border-radius: 16px;
  margin-bottom: 16px;
  padding: 18px 24px;

  .price-item-header {
    font-weight: 600;
    margin: 18px 0;
    padding-bottom: 18px;
    border-bottom: 1px solid #E8EFF0;
  }

  .price-item-option {
    display: flex;
    margin-bottom: 4px;

    > span {
      width: 100px;
      color: rgba(16, 24, 40, 0.4);
      display: block;
    }
  }

  .price-item-btn {
    display: block;
    width: 100%;
    margin-top: 24px;

    .button {
      width: 100%;
    }
  }
}