@import "../../assets/styles/variables.scss";

.contacts-page {
  margin-top: 48px;

  @media screen and (max-width: $screen-mobile) {
    margin-top: 24px;
  }
}

.contacts-blocks {
  display: flex;

  .left-column {
    display: flex;
    align-content: space-between;
    justify-content: space-between;

    @media screen and (max-width: $screen-mobile) {
      flex-direction: column;
    }
  }

  .right-column {
    flex: 1;
  }

  .contacts-blocks-items {
    display: flex;
    flex-direction: column;
    margin-right: 50px;

    .contact-link {
      line-height: 24px;
      margin-bottom: 12px;
      font-weight: 700;
      font-size: 20px;
      display: flex;
      align-items: center;

      &:hover {
        text-decoration: underline;
      }

      svg {
        color: $main-color;
        width: 24px;
        height: 24px;
        margin-right: 16px;
      }
    }

    .address-block, .schedule-block {
      display: flex;
      margin-bottom: 12px;
      font-weight: 500;

      svg {
        color: $main-color;
        width: 24px;
        height: 24px;
        margin-right: 16px;
      }

      .address-block-content, .schedule-block-content {
        font-size: 16px;
      }
    }

    .transport {
      display: flex;
      justify-content: space-between;
      margin-top: 8px;
      gap: 50px;

      .transport-column {
        display: flex;
        flex-direction: column;

        p {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 4px;

          svg {
            color: $main-color;
            width: 16px;
            height: 16px;
            margin-right: 16px;
          }
        }
      }

      @media screen and (max-width: $screen-mobile) {
        flex-direction: column;
        gap: 0;
      }
    }

    @media screen and (max-width: $screen-mobile) {
      margin-top: 16px;
    }
  }

  @media screen and (max-width: $screen-mobile) {
    flex-direction: column;
  }
}

.contacts-map {
  margin-top: 24px;
  position: relative;
  height: 600px;

  @media screen and (max-width: $screen-mobile) {
    height: 300px;
  }
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}