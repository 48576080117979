@import "./assets/styles/variables.scss";

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  flex: 1;
}

.content-section {
  width: 1170px;
  margin: 0 auto 70px;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;

  &--without-margin {
    margin: 0 auto;
  }

  @media screen and (max-width: $screen-mobile) {
    padding: 16px;
    margin: 0 auto;
    width: 100%;
  }
}

#new-widget {
  position: relative;
}

.y8Uf0 {
  background: #00BCD6 !important;
}