@import "../../assets/styles/variables.scss";

.help-banner {
    background: linear-gradient(108.28deg, #B7DAC1 32.53%, #DEECDD 67.93%);
    border-radius: 16px;
    overflow: hidden;
    height: 407px;
    margin-top: 48px;
    padding-left: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    @media screen and (max-width: $screen-mobile) {
        height: auto;
        padding: 40px;
        flex-direction: column;
        margin-top: 0;
    }
}

.help-banner-left-column {
    flex: 1.5;

    .help-banner-text-block {
        margin-bottom: 34px;

        .help-banner-top-header {
            font-weight: 700;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: rgba(16, 24, 40, 0.7);
        }

        .help-banner-header {
            font-style: normal;
            font-weight: 700;
            font-size: 48px;
            line-height: 52px;
            margin-bottom: 20px;

            @media screen and (max-width: $screen-mobile) {
                font-size: 32px;
                line-height: 36px;
            }
        }

        .help-banner-text {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: rgba(16, 24, 40, 0.7);
        }
    }
}

.help-banner-right-column {
    position: relative;
    height: 100%;
    flex: 1;
    border-radius: 150px 0 0 150px;
    overflow: hidden;

    .help-banner-image {
        width: 100%;
        background: url("../../assets/images/staff.png") no-repeat center center;
        background-size: cover;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
    }
}

