.feedback-block {
  width: 100%;
  height: 800px;
  overflow: hidden;
  position: relative;
  margin: auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}