.burger {
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 25px;
  right: 20px;
  z-index: 110;
  display: none;

  @media (max-width: 768px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }

  &.open {
    position: fixed;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background-color: #ccc;
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;

    &:nth-child(1) {
      transform: rotate(0);
    }

    &:nth-child(2) {
      transform: translateX(0);
      opacity: 1;
    }

    &:nth-child(3) {
      transform: rotate(0);
    }
  }

  &.open {
    div {
      &:nth-child(1) {
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        transform: translateX(100%);
        opacity: 0;
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
      }
    }
  }
}

.nav-menu {
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  z-index: 100;

  li {
    padding: 16px 10px;
  }

  @media (max-width: 768px) {
    flex-flow: column nowrap;
    background-color: #fff;
    position: fixed;
    transform: translateX(100%);
    top: 85px;
    right: 0;
    height: 100vh;
    width: 100%;
    padding-top: 16px;
    transition: transform 0.3s ease-in-out;

    li {
      color: #000;
    }
  }

  &.open {
    transform: translateX(0);
    height: calc(100% - 85px); // Ensure the menu takes the full height of the viewport
    overflow-y: auto;
  }

  ul {
    overflow-y: auto;
    max-height: calc(100vh - 3.5rem); // Subtract any top padding from the viewport height
    width: 100%; // Make sure the width is defined, otherwise the scrollbar may not appear
  }

  .navigation-item-link {
    position: relative;
    font-size: 16px;
    font-weight: 600;

    .navigation-item-arrow {
      display: inline-block;
      margin-left: 5px;
      border: solid black;
      border-width: 0 3px 3px 0;
      padding: 3px;
      transform: rotate(-45deg);
      transition: transform 0.3s ease-in-out;
    }

    .navigation-item-arrow.active {
      transform: rotate(45deg);
    }

    .sub-menu {
      margin-top: 14px;
      margin-left: 8px;
      display: none;
      flex-direction: column;
      list-style: none;

      li {
        padding: 14px 0 0;
        font-weight: 400;
      }
    }

    .sub-menu.active {
      display: flex;
    }
  }
}
