.input {
    width: 100%;
    background: none;
    border: 1px solid #E3EFF0;
    border-radius: 8px;
    color: rgba(16, 24, 40, 0.8);
    box-shadow: none;
    background: #fff;
    padding: 15px;
    outline: 0;
    display: inline-block;

    &::placeholder {
        color: rgba(16, 24, 40, 0.4);
    }
}