@import '../../assets/styles/variables.scss';

.contacts-block {
  padding: 34px 40px 40px 40px;
  background: #fff;
  border-radius: 16px;
  width: 443px;
  height: 100%;
  color: #222d31;

    @media screen and (max-width: $screen-mobile) {
        width: 100%;
        padding: 40px;
    }
}

.contacts-block-header {
  font-size: 32px;
  line-height: 36px;
}

.contacts-block-item {
  padding: 12px 0px;
  margin-top: 0px;
  border-bottom: 1px solid #e8eff0;
}

.contacts-block-item-caption {
  font-weight: 400;
  font-size: 12px;
  opacity: 0.4;
  margin-bottom: 3px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.contacts-block-item-text {
  font-size: 16px;
  line-height: 20px;
  color: #101828;
  font-weight: 600;
}

.contacts-block-button {
  margin-top: 30px;
}
