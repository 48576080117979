@import "../../assets/styles/variables.scss";

.main-banner {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #59B8C5;
    border-radius: 16px;
    height: 483px;
    margin-top: 28px;
    padding-left: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .main-banner-left-column {
        flex: 1;
    }

    @media screen and (max-width: $screen-mobile) {
        height: auto;
        padding: 40px;
        flex-direction: column;
        margin-top: 0;
    }
}

.main-banner-text-block {
    margin-bottom: 34px;

    @media screen and (max-width: $screen-mobile) {
        width: 100%;
        text-align: center;
        margin-bottom: 8px;
    }

    .main-banner-caption {
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: rgba(16, 24, 40, 0.7);

        @media screen and (max-width: $screen-mobile) {
            font-size: 12px;
            line-height: 16px;
            padding-bottom: 8px;
            display: block;
        }
    }

    .main-banner-header {
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 52px;
        margin-bottom: 20px;

        @media screen and (max-width: $screen-mobile) {
            font-size: 28px;
            line-height: 32px;
            text-transform: uppercase;
        }
    }

    .main-banner-text {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: rgba(16, 24, 40, 0.7);
    }
}

.main-banner-image {
    flex: 1;
    //margin-right: -40px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        height: auto;
        width: calc(100% - 40px);
        position: relative;
    }

    &::before {
        content: '';
        opacity: 0.8;
        display: block;
        position: absolute;
        bottom: 40px;
        background: linear-gradient(180deg, rgba(89, 184, 197, 0.04) 0, rgba(89, 184, 197, 1.32) 100%);
        border-radius: 100%;
        width: 70%;
        height: 220px;
        box-shadow: rgba(0, 0, 0, 0.2) 10px 10px 30px 0px;
        transform: rotate(351deg);
    }

    @media screen and (max-width: $screen-mobile) {
        margin-right: 0;
        margin-bottom: 20px;

        &::before {
            bottom: 0;
        }
    }
}

.request-call-modal {
    position: relative;
    padding: 40px;


    h2 {
        margin-bottom: 24px;
    }

    p {
        margin-bottom: 24px;
    }

    .request-call-from {
        display: flex;
        flex-direction: column;
    }

    .input-container {
        margin-bottom: 8px;

        .error {
            margin-top: 8px;
            color: red;
            font-size: 12px;
            padding-left: 4px;
        }
    }

    .button-container {
        display: flex;
        margin-top: 16px;
        width: 100%;

        .button {
            flex: 1
        }
    }
}

.main-banner-btn-mobile-block {
    display: none;

    @media screen and (max-width: $screen-mobile) {
        display: block;
    }
}

.main-banner-btn-desktop-block {
    @media screen and (max-width: $screen-mobile) {
        display: none;
    }
}