@import "../../assets/styles/variables.scss";

.repair-process {
  position: relative;
  margin-top: 70px;

  @media screen and (max-width: $screen-mobile) {
    margin-top: 0;
  }

  .repair-process-header {
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    color: #ffffff;
    margin-bottom: 42px;
  }

  .content-section {
    margin-top: 30px;
    z-index: 10;
  }

  .repair-process-steps {
    position: relative;
    display: flex;
    list-style: none;
    width: 100%;

    .repair-process-steps-list {
      display: flex;

      @media screen and (max-width: $screen-mobile) {
        flex-direction: column;
      }
    }

    .repair-process-step {
      z-index: 10;
      list-style: none;
      border: 0px solid #fff;
      width: 215px;
      height: 166px;
      margin-right: 23.5px;
      border-radius: 16px;
      padding: 17px 24px 24px 24px;
      min-height: 166px;
      position: relative;
      box-shadow: 0px 3px 30px rgba(6, 63, 70, 0.09), 0px 33.8399px 30px rgba(73, 80, 84, 0.03), 0px 18.0924px 28.5905px rgba(73, 80, 84, 0.03), 0px 5.38658px 8.51213px rgba(73, 80, 84, 0.0444282);
      background: #fff;
      box-sizing: border-box;
      transition-duration: .2s;
      display: flex;
      flex-direction: column;
      font-weight: 600;

      @media screen and (max-width: $screen-mobile) {
        width: 100%;
        height: 84px;
        min-height: auto;
        padding: 12px 16px 24px 16px;
        flex-direction: row;
        margin-bottom: 16px;

        &__line {
          position: absolute;
          height: 4px;
          left: 8px;
          right: 6px;
          bottom: 8px;
          background: #59B8C5;
          border-radius: 0px 0px 8px 8px;
        }
      }

      .step-number {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: #00BCD6;
        margin-bottom: 18px;

        @media screen and (max-width: $screen-mobile) {
          padding: 0px 16px 9px 0px;
        }
      }
    }
  }

  .repair-process-background {
    width: 100%;
    position: absolute;
    top: 0;
    height: 100%;
    background: linear-gradient(91.59deg, #00BCD6 -31.56%, #157C8A 110.19%);
    backdrop-filter: blur(75px);

    @media screen and (max-width: $screen-mobile) {
      height: 100%;
    }
  }
}