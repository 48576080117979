.models-block {
  margin-top: 72px;

  h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 36px;
    color: #101828;
  }

  .models-block-items {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(19%, 1fr));
    width: 100%;
    gap: 10px;
    flex-wrap: wrap;

    @media (max-width: 600px) { /* Adjust breakpoint as needed for mobile view */
      grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
    }

    .models-block-item-link {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #59B8C5;
      padding: 20px;
      cursor: pointer;
      display: block;
      color: #101828;
      border-radius: 8px;
      font-weight: 600;

      img {
        width: 150px;
      }

      &:hover {
        background: #3fa7b2;
      }

      &-with-image {
        display: flex;
        flex-direction: column;
        background: none;
        align-items: center;
        gap: 16px;

        &:hover {
          background: none;
          //  scale up on hover with animation
          transform: scale(1.1);
          transition: transform 0.3s;
        }
      }
    }
  }
}