@import "../../assets/styles/variables.scss";

.header {
  //background-color: #222d31;
  background-color: #000;
}

.header-wrapper {
  padding: 0;
  width: 1170px;
  margin: 0 auto;
  position: relative;
  max-width: 100%;
}

.header-menu {
  padding: 16px 0;
  display: flex;
  align-items: center;
  height: 100%;
  color: #fff;
}

.header-menu-column {
  padding: 0 30px;
  display: flex;
  height: 100%;
  border-right: 1px solid #4b5457;

  @media screen and (max-width: $screen-mobile) {
    padding: 0 20px;
  }

  &:last-child {
    border-right: none;
  }
}

.header-logo {
  display: flex;
  height: 100%;

  .logo-link {
    display: flex;
    align-items: center;
    height: 100%;
    flex-direction: column;

    img {
      width: 100px;
    }

    .logo-subtitle {
      font-weight: 300;
      font-size: 12px;
      color: #ffffff;
      opacity: 0.4;
      margin-top: 8px;

        @media screen and (max-width: $screen-mobile) {
            display: none;
        }
    }
  }
}

.header-logo-icon {
  margin-bottom: 8px;
}

.header-address {
  svg {
    opacity: 0.6;
  }

  .header-address-text {
    float: right;
    text-align: left;
    margin-left: 8px;
    display: flex;
    flex-direction: column;
    line-height: 1;
    font-size: 14px;

    span {
      margin-bottom: 4px;
    }
  }

  @media screen and (max-width: $screen-mobile) {
    display: none;
  }
}

.header-contacts-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
  gap: 20px;

  @media screen and (max-width: $screen-mobile) {
    display: none;
  }
}

.header-work-time {
  margin-top: 12px;
  font-size: 12px;
  opacity: 0.6;
}

.header-contacts {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .header-contact {
    .header-contact-link {
      font-weight: 400;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        color: #ffffff;
        width: 16px;
        height: 16px;
        margin-right: 8px;
        opacity: 0.6;
      }
    }
  }
}

.header-social-mobile {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-right: 60px;

  .info-button {
    color: #59b8c5;
    margin: 0 10px 0 0;

    width: 28px;
    height: 28px;
  }
}