.brand-block-items {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
    grid-gap: 12px;
    margin-top: 48px;

    .brand-block-item {
        min-height: 110px;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #59B8C5;
        border-radius: 16px;
        cursor: pointer;
        transition-duration: .2s;
        position: relative;

        &:hover {
            box-shadow: 0 6px 11px rgba(0,0,0,0.15);
            background: linear-gradient(10deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), #59B8C5;

            .brand-block-item-link {

                .brand-block-item-image {
                    transform: scale(1.1);
                    color: #ffffff;
                }
            }
        }

        &::before {
            content: '';
            display: block;
            padding-top: 100%; // 1:1 aspect ratio
        }

        .brand-block-item-link {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
            text-decoration: none;

            .brand-block-item-image {
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                transition-duration: .3s;
                color: #59B8C5;
            }
        }
    }
}
