.button {
    border: none;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-weight: 500;
    padding: 14px 19px;
    line-height: 1.5;
    margin: 0;
    text-align: center;
    text-decoration: none;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    font-size: 16px;
    background-color: #59B8C5;
    border-radius: 8px;

    &:hover {
      background-color: #3fa7b2;
      text-decoration: none;
    }

    &--big {
        font-weight: 500;
        padding: 14px 19px;
    }

    &--small {
        font-weight: 500;
        padding: 8px 18px;
    }
}