.social-links {
  display: flex;
  align-items: center;
  height: 100%;
  color: #fff;

  .social-link {
    color: #59b8c5;
    margin: 0 10px 0 0;

    svg {
      width: 28px;
      height: 28px;
    }
  }
}
