// Home page styles
.device-block {
  margin-top: 70px;
}

.categories-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  color: #101828;
  text-align: center;
}

.categories {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 20px;
  //text-align: center;
  //justify-content: space-between;
}

.category-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  text-align: center;
  width: 50%;
  //max-width: 200px;
  padding: 20px;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  color: #101828;
  transition-duration: .2s;

  img {
    width: 250px;
    @media (max-width: 600px) { /* Adjust breakpoint as needed for mobile view */
      width: 150px;
    }
  }

  &:hover {
    transform: scale(1.1);
    transition: transform 0.3s;
  }
}