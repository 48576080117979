.payment-method-list {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    list-style: none;
    margin: 0 10px 0 0;
  }
}
