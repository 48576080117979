.text-banner {
  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    margin: 21px 0;
  }

  ul {
    padding-left: 18px;
    line-height: 28px;
  }

  li::marker {
    color: #59B8C5;
    width: 8px;
    height: 8px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    opacity: 0.85;
    margin-top: 21px;
  }
}