@import "../../assets/styles/variables.scss";

.footer {
  //background-color: #222d31;
  background-color: #000;
  color: #fff;

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
}

.footer-wrapper {
  padding: 0;
  width: 1170px;
  margin: 0 auto;
  position: relative;
  max-width: 100%;

  @media screen and (max-width: $screen-mobile) {
    width: 100%;
  }
}

.footer-content {
  padding: 16px 0;
  display: flex;
  flex-direction: column;

  .footer-top-section {
    display: flex;
    justify-content: space-between;
    padding: 8px;

    @media screen and (max-width: $screen-mobile) {
      flex-direction: column;
      gap: 0;
    }

    .footer-content-column {
      float: left;
      margin-left: 26px;
    }
  }

  @media screen and (max-width: $screen-mobile) {
    flex-wrap: wrap;
    padding: 0;
  }
}

.footer-row-section {
  display: flex;
  width: 100%;
  flex: 1;
  gap: 16px;
}

.footer-navigation {
  display: flex;
  width: 100%;
  flex: 1;
  gap: 16px;

  .footer-navigation-column {
    width: 50%;
  }
}

.footer-contacts {
  display: flex;
  flex-direction: column;

  .footer-contact {
    line-height: 24px;
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;

    svg {
      color: #ffffff;
      opacity: 0.6;
      width: 18px;
      height: 18px;
      margin-right: 10px;
    }
  }
}

.footer-social {
  font-size: 12px;
  padding: 30px 0;
}

.footer-social-link {
  margin-right: 10px;
  color: #fff;
  text-decoration: none;
  font-size: 20px;
}

.footer-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  color: #fff;

  .footer-menu-header {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    height: 86px;
  }

  .footer-menu-content {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
  }

  .footer-menu-list {
    display: grid;
    text-align: left;
    margin: 10px 0;
    padding: 0;
  }

  .footer-menu-item {
    display: inline-block;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .footer-menu-link {
    display: block;
    padding: 10px 0px 0 0;
    height: 100%;
    color: #fff;
    text-decoration: none;
    font-weight: 300;
    font-size: 12px;
    transition: all 0.3s ease;
  }
}

.footer-logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  margin-bottom: 10px;

  img {
    width: 100px
  }

  @media screen and (max-width: $screen-mobile) {
    padding: 0;
  }

  .logo-subtitle {
    font-weight: 300;
    font-size: 12px;
    color: #ffffff;
    opacity: 0.4;
    margin-top: 8px;
  }
}

.footer-address {
  svg {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    color: #ffffff;
    opacity: 0.6;
  }

  .footer-address-content {
    float: right;
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      padding: 0;
      margin: 0;
      opacity: 0.8;
    }
  }
}

.footer-legal {
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    opacity: 0.8;
    margin-top: 2px;
  }
}

.footer-bottom-section {
  padding: 1rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0.8;

  @media screen and (max-width: $screen-mobile) {
    flex-direction: column-reverse;
    gap: 14px;

    p {
      font-size: 14px;
      font-weight: 300;
    }
  }
}

.footer-partner-image {
  width: 100%;
  max-width: 120px;
  margin: 5px 0;
}