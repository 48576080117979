@import "../../assets/styles/variables.scss";

.device-block-items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 48px;
    gap: 16px;

    @media screen and (max-width: $screen-mobile) {
        flex-wrap: wrap;
    }

    .device-block-item {
        width: 100%;
        padding: 27px 30px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #59B8C5;
        border-radius: 16px;
        cursor: pointer;
        transition-duration: .2s;

        &:hover {
            box-shadow: 0 6px 11px rgba(0,0,0,0.15);

            .device-block-item-text {
                transform: scale(1.1);
            }

            .device-block-item-image {
                transform: scale(1.1);
            }
        }

        .device-block-item-text {
            transition-duration: .3s;

            .device-block-item-text-title {
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
            }

            .device-block-item-text-price {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: rgba(16, 24, 40, 0.698);
                margin-top: 8px;
            }
        }

        .device-block-item-image {
            width: 100%;
            height: 100%;
            object-fit: contain;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 24px;
            transition-duration: .3s;

            .img-background {
                display: block;
                background: linear-gradient(180deg, rgba(89, 184, 197, 0.04) 0%, rgba(89, 184, 197, 0.32) 100%);
                border-radius: 100%;
                position: absolute;
                aspect-ratio: 1/1;
                height: 100%;
            }

            img {
                z-index: 10;
                width: 100%;
            }
        }

        @media screen and (max-width: $screen-mobile) {
            width: 47%;
            padding: 12px 14px;
        }
    }
}