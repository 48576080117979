.notification {
    z-index: 1000;
    //padding: 1rem;
    align-items: center;
    //background-color: #e93a26;
    font-weight: 500;
    //color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    //margin: 1rem;
    display: flex;
    justify-content: space-between;
    //min-width: 300px;
    //max-width: 500px;
    width: 100%;
    padding: 1rem;
    animation: slideIn 0.5s ease-in-out;
    align-content: center;
}