.navigation-submenu-wrapper {
  position: absolute;
  z-index: 101;
  background: #ffffff;
  left: 0; // Change this from calc(-50vw + 50%)
  right: 0; // Add this to make it stretch to the right edge
  top: 58px;
  border-bottom: 0px solid #fff;
  box-shadow: 0 5px 40px rgba(0, 0, 0, 0.1);
  //padding: 20px calc(50vw - 50%) 20px calc(50vw - 50%);
}

.navigation-submenu {
  width: 1170px;
  padding: 20px 0; // Change this from 20px calc(50vw - 50%) 20px calc(50vw - 50%);
  margin: 0 auto;
  list-style: none;

  .navigation-submenu-item {
    float: left;
    width: 24%;
    margin: 0 1% 20px 0;

    a {
      font-size: 16px;
      line-height: 24px;
      color: #101828;
      font-weight: 800;
      text-transform: uppercase;
      transition-duration: 0.2s;

      &:hover {
        color: #59b8c5;
        text-decoration: none;
      }
    }
  }
}
