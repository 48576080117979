@import "../../assets/styles/variables.scss";

.benefits {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 24px;

  .benefits-wrapper {
    width: 1170px;
    margin: 0 auto;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 70px;

    @media screen and (max-width: $screen-mobile) {
      width: 100%;
      flex-wrap: wrap;
      gap: 0;
    }
  }

  .benefits-item {
    display: flex;
    flex: 1;

    @media screen and (max-width: $screen-mobile) {
      flex: auto;
      width: 50%;
      flex-direction: column;
      padding-bottom: 30px;

      &:not(:nth-child(2n)) {
        padding-right: 30px;
      }
    }
  }

  .benefits-item-title {
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 14px;
  }

  .benefits-item-icon {
    height: 100%;
    margin-right: 16px;

    @media screen and (max-width: $screen-mobile) {
      height: auto;
      margin-right: 0;
      margin-bottom: 8px;
    }

  }

  .benefits-item-text {
    font-size: 14px;
    line-height: 20px;
    color: #101828;
    //padding-left: 56px;
    //padding-right: 20px;
    position: relative;
    opacity: 0.7;
    font-weight: 400;
  }
}