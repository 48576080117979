.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: all 0.25s ease-in-out;
  z-index: 100;

  &.open {
    opacity: 1;
    visibility: visible;
  }

  .modal-content {
    position: relative;
    overflow: hidden;
    background: white;
    border-radius: 16px;
    transform: scale(0.9);
    transition: all 0.25s ease-in-out;
    width: 600px;

    .close {
      z-index: 10;
      position: absolute;
      right: 24px;
      top: 24px;
      cursor: pointer;
    }

    &.open {
      transform: scale(1);
    }
  }
}
