.map-block {
  height: 600px;
  position: relative;
}

.map-content {
  height: 100%;
}

.contacts-block-wrapper {
  height: 100%;
  display: block;
  text-align: left;
  padding: 32px 0;
  position: absolute;
  right: 0;
  top: 0;
}